import Map from "./map";

function Footer() {
    return <div className="footerDiv">
        <div>
            <div className="imonesInfo">
                <img src="../assets/LogoFooter.svg" alt="logo"></img>
                <h3>JSC Ukmergės staklės</h3>
                <div className="footerTextDiv">Linų str. 81, LT-20174 Ukmergė, Lithuania<br/><br/></div>

                <div className="footerTextDiv">
                    Company code 183140725
                    <br/>VAT code LT831407219
                </div>

                <div className="border"></div>

                <div className="footerKontaktai">
                    +370 614 83 604
                    <br/>sales@usta.lt
                </div>
            </div>
            <a rel="noreferrer noopener" target="_blank" href='https://maps.app.goo.gl/2gdFi2dY5fbngc3JA'>
                <Map></Map>
            </a>
            {/* <div className='location'><img src='../assets/Zemelapis.png' className='locationImage' alt='map'></img></div> */}
        </div>
    </div>
}

export default Footer;