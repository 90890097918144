import { useState } from "react";
import emailjs from '@emailjs/browser';

function Susisiekimas(){

    const [formData, setFormData] = useState(
        {
            vardas: "",
            telnr: "",
            elpastas: "",
            zinute: "",
        }
    )

    const [errors, setErrors] = useState(
        {
            vardas: "",
            telnr: "",
            elpastas: "",
            zinute: "",
        }
    )

    const [messageSent, setMessageSent] = useState(false)
    const [handlingSubmit, setHandlingSubmit] = useState(false)

    const validate = (fields) => {
        var updatedErrors
        fields.forEach((field) => {
            const value = document.getElementsByName(field)[0].value
            console.log(value)

            if(field === "vardas"){
                let change
                if(value.length < 2){
                    change = {
                        [field]: "Required",
                    }
                }else{
                    change = {
                        [field]: "",
                    }
                }
                updatedErrors = { ...updatedErrors, ...change}
            }

            if(field === "elpastas"){
                let change

                var input = document.createElement('input');
                input.type = 'email';
                input.required = true;
                input.value = value;

                if(!input.checkValidity()){
                    change = {
                        [field]: "Required",
                    }
                }else{
                    change = {
                        [field]: "",
                    }
                }
                updatedErrors = { ...updatedErrors, ...change}
            }

            if(field === "zinute"){
                let change
                if(value.length < 2){
                    change = {
                        [field]: "Required",
                    }
                }else{
                    change = {
                        [field]: "",
                    }
                }
                updatedErrors = { ...updatedErrors, ...change}
            }
        })

        setErrors((old) => {
            let allUpdatedErrors = { ...old, ...updatedErrors}
            return allUpdatedErrors
        })

        return updatedErrors
    }

    const handleInputChange = (event) => {
        const { name, value } = event.target
        let updatedValue = value

        const change = {
            [name]: updatedValue,
        }

        let updatedFormData

        setFormData((old) => {
            updatedFormData = { ...old, ...change}
            return updatedFormData
        })

    }

    const handleFormSubmit = () => {
        setHandlingSubmit(true)
        let names = ["vardas", "elpastas", "zinute"]
        const newErrors = validate(names)
        const isOk = newErrors.vardas.length === 0 && newErrors.elpastas.length === 0 && newErrors.zinute.length === 0
        if(isOk){
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0');
            var yyyy = today.getFullYear();
            today = mm + " " + dd + " " + yyyy;

            const objectToEmail = {
                data: today,
                vardas: formData.vardas,
                elpastas: formData.elpastas,
                telnr: formData.telnr,
                zinute: formData.zinute,
            }

            console.log(objectToEmail)

            emailjs.send('default_service','template_h9wwjbb', objectToEmail, 'adrGEUlwnE4cpz5kM')
                .then((response) => {
                console.log('SUCCESS!', response.status, response.text);
                // alert("Form sent, we will contact you as soon as possible")
                setMessageSent(true)
                setFormData(
                    {
                        vardas: "",
                        telnr: "",
                        elpastas: "",
                        zinute: "",
                    }
                )
                setErrors(
                    {
                        vardas: "",
                        telnr: "",
                        elpastas: "",
                        zinute: "",
                    }
                )
                setHandlingSubmit(false)
                }, (err) => {
                console.log('FAILED...', err);
                setHandlingSubmit(false)
                });
        }else{
            setHandlingSubmit(false)
        }
    }

    return(
        <section className={messageSent ? "susisiekimas sent" : "susisiekimas"} id="susisiekimo-section">
            <h3>Let's get in touch</h3>
            <div className="kont">
                <div className="kont1">Please fill out the request form and we will contact you!</div>
                <div className="kont2">
                    <div>+370 687 13 425</div>
                    <div>+370 614 83 604</div>
                </div>
                <div className="kont3">sales@usta.lt</div>
            </div>
            {!messageSent ? <div className="formDiv">
                <form>
                    <div className="kontaktai">
                        <div>
                            <input type="text" name="vardas" className="inputDiv" value={formData.vardas} onChange={e => handleInputChange(e)}></input>
                            <div className={errors.vardas.length > 0 ? "inputComment activeError": "inputComment"}>Name, Surname *</div>
                            {errors.vardas.length > 0 && (
                                <div className="validationError">
                                    <p>{errors.vardas}</p>
                                </div>
                            )}
                        </div>
                        <div>
                            <input type="text" name="telnr" className="inputDiv" value={formData.telnr} onChange={e => handleInputChange(e)}></input>
                            <div className={errors.telnr.length > 0 ? "inputComment activeError": "inputComment"}>Mob. number</div>
                        </div>
                        <div>
                            <input type="text" name="elpastas" className="inputDiv" value={formData.elpastas} onChange={e => handleInputChange(e)}></input>
                            <div className={errors.elpastas.length > 0 ? "inputComment activeError": "inputComment"}>E-mail *</div>
                            {errors.elpastas.length > 0 && (
                                <div className="validationError">
                                    <p>{errors.elpastas}</p>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="zinute">
                        <div>
                            <textarea name="zinute" className="textInputDiv" value={formData.zinute} onChange={e => handleInputChange(e)}></textarea>
                            <div className={errors.zinute.length > 0 ? "inputComment activeError": "inputComment"}>Your message *</div>
                            {errors.zinute.length > 0 && (
                                <div className="validationError">
                                    <p>{errors.zinute}</p>
                                </div>
                            )}
                        </div>
                        <button type="button" onClick={() => handleFormSubmit()} disabled={handlingSubmit ? true : false}>Send</button>
                    </div>
                </form>
            </div>
            :
            <div className="formSuccessDiv">
                <h3>Your message was sent successfully!</h3>
                <div className="successMsg">Thank you for submitting,<br/> we will be contacting You shortly!</div>
                <img src="../assets/IksasBaltas.svg" alt="X" className="iksas" onClick={() => {setMessageSent(false)}}></img>
                <button onClick={() => {setMessageSent(false)}}>Close</button>
            </div>
            }
        </section>
    );
}

export default Susisiekimas;