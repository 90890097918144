import React, { useState } from "react";
import ProductCard from "./ProductCard";
import { Product } from "./Product";
import { MOCK_PRODUCTS } from "./MockProducts";

function ProductList(props){
    let {products} = props
    const [open, setOpen] = useState(false)
    const [displayedProducts, setDisplayedProducts] = useState(4)
    const handleOpen = () => {
        setOpen(!open)
    }
    const handleMoreProducts = () => {
        setDisplayedProducts(displayedProducts + 4)
    }
    let items    
    items = products.map(product => (
        <ProductCard key={product.id} product={product}></ProductCard>
    ))
    return <>
        <div className="productList">
        {items}
        <div className="galimybes">
            <h3>Our capabilities</h3>
            <p> Turning (CNC with milling option), CNC slotting, CNC gear milling, coordinate turning, drilling, grinding, laser cutting, 
                bending, welding, heat treatment carbonizing, hardening, surface hardening with high-frequency electric current, 
                and other metalworking. <br/><br/>

                <div className={open ? "galimybesPlaciau galimybesOpen" : "galimybesPlaciau"}>Defect identification, restoration and repair of axles, 
                gears, gear reducers, moving units of tractors, excavators, forestry machinery, loaders and other machinery. <br/><br/>
                
                We carry out production using materials such as carbon steel, alloy steel, low-carbon carbonized steel, stainless steel, non-ferrous metals, plastics, textolite, and other materials.
                We undertake both serial and custom production based on provided designs or samples, meeting all technological requirements.</div></p>

            <div className="buttonDiv" onClick={() => handleOpen()}>More
                {open ? 
                    <i className="arrow up" style={{top: "2px"}}></i> : 
                    <i className="arrow down"></i>
                }
            </div>
        </div>
        </div>

        <div className="productList productListMobile">
        {items.slice(0, displayedProducts)}
        {displayedProducts < 12 && <div className="download" onClick={() => handleMoreProducts()}>More products <i className="arrow down" style={{borderBottom: "solid white", borderRight: "solid white"}}></i></div>}
        <div className="galimybes">
            <h3>Our capabilities</h3>
            <p> Turning (CNC with milling option), CNC slotting, CNC gear milling, coordinate turning, drilling, grinding, laser cutting, 
                bending, welding, heat treatment carbonizing, hardening, surface hardening with high-frequency electric current, 
                and other metalworking. <br/><br/>

                <div className={open ? "galimybesPlaciau galimybesOpen" : "galimybesPlaciau"}>Defect identification, restoration and repair of axles, 
                gears, gear reducers, moving units of tractors, excavators, forestry machinery, loaders and other machinery. <br/><br/>
                
                We carry out production using materials such as carbon steel, alloy steel, low-carbon carbonized steel, stainless steel, non-ferrous metals, plastics, textolite, and other materials.
                We undertake both serial and custom production based on provided designs or samples, meeting all technological requirements.</div></p>
            <div className="buttonDiv" onClick={() => handleOpen()}>More
                {open ? 
                    <i className="arrow up" style={{top: "2px"}}></i> : 
                    <i className="arrow down"></i>
                }
            </div>
        </div>
        </div>
    </>
}

export default ProductList