import Header from "./header/header";
import Footer from "./footer/footer";
import Susisiekimas from "./Susisiekimas";

function ApieMus(){
    return(
        <>
            <Header activeNav={2}></Header>
            <div className="apieMus">
                <div className="apieMusTop">
                    <div>
                        <h3>About us</h3>
                        <div className="textDiv">We are one of the largest manufacturers of high-quality mechanical multipurpose components in Lithuania. We specialise in the manufacture of shafts, gears, pulleys, couplings, racks, chains, worms, worm wheels, blades, and other complex parts and components, as well as repair and restoration services. We also perform precision machining of products.</div>
                    </div>
                    <img src="assets/Logo.svg" alt="logo"></img>
                </div>
                <div className="apieMusBot">
                    <h3>Our work</h3>
                    <div>
                        <div className="textDiv">   Our services include turning (CNC with milling option), CNC turning, slotting, CNC gear tooth milling, coordinate boring, drilling, grinding, punching, bending, welding, thermal processing (carbonization, hardening, high-frequency electric current surface hardening), and other metalworking operations.

                                <br/><br/>We carry out production using materials such as carbon steel, alloy steel, low-carbon carbonized steel, stainless steel, non-ferrous metals, plastics, textolite, and other materials.</div>
                        <div className="textDiv">   We undertake both serial and custom production based on provided designs or samples, meeting all technological requirements.

                                <br/><br/>We also specialise in the inspection, restoration, and repair of axes, plough gearboxes, reducers, and moving assemblies of tractors, excavators, forestry machinery, heavy machines.</div>
                    </div>
                </div>
            </div>
            <div className="operacijos apieMusOp">
                <h3>Operations</h3>
                <img src="assets/Operacijos.svg" alt="operacijos"></img>
            </div>
            <Susisiekimas></Susisiekimas>
            <Footer></Footer>
        </>
    )
}

export default ApieMus