import { Product } from "./Product";

export const MOCK_PRODUCTS = [
    new Product({
        id: 1,
        name: "Shafts",
        products: [
            "Shafts", "Gears-shafts", "Half-shafts", "Cardan shafts"
        ],
        allProducts: [
            "Shafts", "Gears-shafts", "Half-shafts", "Cardan shafts"
        ],
        tech: [
            "Length up to 2500 mm with splines",
            "Up to Ø120 mm, length up to 1000 mm",
            "Up to Ø80 mm, length from 1000 mm ",
            "Gears-shafts up to Ø200 mm, length up to 1000 mm",
            "Grinding lengths up to 1000 mm for outer shaft surfaces",
            <br/>,
            "Turning ",
            "Ø10-80 mm, length up to 3000 mm",
            "Ø80-200 mm, length up to 2000 mm",
            "Ø200-700 mm, length up to 500 mm ",
            "Ø700- 900 mm, length up to 300 mm",
            <br/>,
            "Grinding",
            "Up to Ø250 mm, length up to 1000 mm, weight up to 100 kg",
            "Milling of splines on shafts up to Ø250 mm, length up to 1100 mm"
        ],
        bgColor: "#001A3D",
        iconLocation: [0, 223],
        imgNum: 12,
    }),
    new Product({
        id: 2,
        name: "Gears",
        products: [
            "Gears", "Gear drives", "Gearboxes", "Block gears"
        ],
        allProducts: [
            "Gears", "Gear drives", "Gearboxes", "Block gears"
        ],
        tech: [
            "Spur gears with straight external teeth up to Ø1000 mm, module m0.5–14",
            "Bevel gears with straight teeth Ø20-Ø500 mm, module m1-10",
            "Helical gears up to Ø1000 mm, module m1-14",
            "Racks and pinions Ø15-1000 mm, module m0.5–14",
            <br/>,
            "Turning",
            "Ø10-80 mm, length up to 3000 mm",
            "Ø80-200 mm, length up to 2000 mm",
            "Ø200-700 mm, length up to 500 mm",
            "Ø700- 900 mm, length up to 300 mm",
            <br/>,
            "Sanding ",
            "Up to Ø250 mm, length up to 1000 mm, weight up to 100 kg",
            "Milling of splines on shafts up to Ø250 mm, length up to 1100 mm",
            "Gear recession slotting up to Ø 700 mm, width from 3 mm, length depending on width"
        ],
        bgColor: "#EDEDED",
        iconLocation: [0, 310],
        imgNum: 6,
    }),
    new Product({
        id: 3,
        name: "Pulleys",
        products: [
            "Trapezoidal profile pulleys", "Single-strand pulleys", "Multi-strand pulleys"
        ],
        allProducts: [
            "Trapezoidal profile pulleys", "Single-strand pulleys", "Multi-strand pulleys"
        ],
        tech: [
            "Turning",
            "Ø10-80 mm, length up to 3000 mm",
            "Ø80-200 mm, length up to 2000 mm",
            "Ø200-700 mm, length up to 500 mm ",
            "Ø700- 900 mm, length up to 300 mm",
            <br/>,
            "Gear recession slotting",
            "Up to Ø 700 mm, width from 3 mm, length depending on width",
            <br/>,
            "Grinding of internal holes ",
            "From Ø350 mm, length up to 500 mm, weight up to 100 kg, hole from Ø10 mm"
        ],
        bgColor: "#EDEDED",
        iconLocation: [0, 292],
        imgNum: 2,
    }),
    new Product({
        id: 4,
        name: "Couplings",
        products: [
            "Couplings", "Transition adapters", "Flanges", "Cam couplings", "..."
        ],
        allProducts: [
            "Couplings",
            "Transition adapters for work shafts 8x6, 6x8, 8x12, 21x8, 6x21, 21x6 mm",
            "Flanges",
            "Cam couplings",
            "Couplings with splines",
            "Conical couplings",
            "Clamp-on couplings",
        ],
        tech: [
            "Turning",
            "Ø10-80 mm, length up to 3000 mm",
            "Ø80-200 mm, length up to 2000 mm",
            "Ø200-700 mm, length up to 500 mm ",
            "Ø700-900 mm, length up to 300 mm",
            <br/>,
            "Gear recession slotting",
            "Up to Ø 700 mm, width from 3 mm, length depending on width",
            "Splines slotting",
            <br/>,
            "Grinding of internal holes ",
            "From Ø350 mm, length up to 500 mm, weight up to 100 kg, hole from Ø10 mm"
        ],
        bgColor: "#EDEDED",
        iconLocation: [0, 266],
        imgNum: 4,
    }),
    new Product({
        id: 5,
        name: "Chains",
        products: [
            "Chains for agricultural machinery", "Chains for excavators", "Specific trenching chains", "Non-standard chain connections", "..."
        ],
        allProducts: [
            "Multi-pitch standard and non-standard chains for agricultural machinery",
            "Chains for single and multi-shovel excavators",
            "Specific trenching chains",
            "Non-standard chain connections",
            "Links for multi-line chains"
        ],
        tech: [
            "Pitch t50-300 mm for inch pitch chains ",
            "Conveyor chain pitch t50-300 mm"
        ],
        bgColor: "#001A3D",
        iconLocation: [0, 327],
        imgNum: 5,
    }),
    new Product({
        id: 6,
        name: "Sprockets",
        products: [
            "Single & multi-strand sprockets ", "Sprockets for conveyors", "Sprockets for excavation equipment", "..."
        ],
        allProducts: [
            "Single-strand and multi-strand sprockets for agricultural machinery:",
            "for seeders, for balers, combine harvesters",
            "Drive, tension, and other sprockets for conveyors",
            "Sprockets for excavation equipment, industrial machinery"
        ],
        tech: [
            "Chains with bushings and rollers, pitch 8-50.8 mm",
            "Up to Ø1000 mm, milling with universal gear milling machines",
            "Up to Ø350 mm, milling with 6-axis CNC gear milling machine",
            <br/>,
            "Inch, single-strand t5-44.5 mm, up to Ø1000 mm",
            "Inch, multi-strand t5-44.5 mm, up to Ø1000 mm",
            "Conveyor t50-250 mm, Ø – according to situation"
        ],
        bgColor: "#EDEDED",
        iconLocation: [0, 286],
        imgNum: 4,
    }),
    new Product({
        id: 7,
        name: "Pinion racks",
        products: [
            "Cylindrical pinion racks", "Square pinion racks", "Custom pinion racks", "Rack and pinion drives"
        ],
        allProducts: [
            "Cylindrical pinion racks",
            "Square pinion racks",
            "Custom straight, diagonal pinion racks",
            "Rack and pinion drives for forestry and handling machinery and industrial equipment"
        ],
        tech: [
            "Module m4-14, length up to 1750 mm, weight up to 500 kg"
        ],
        bgColor: "#838C98",
        iconLocation: [0, 348],
        imgNum: 3,
    }),
    new Product({
        id: 8,
        name: "Worms & wormwheels",
        products: [
            "Worm gears (gearboxes)", "Single-strand and multi-strand worms", "Wormwheels",
        ],
        allProducts: [
            "Worm gears (gearboxes)",
            "Single-strand and multi-strand worms",
            "Wormwheels"
        ],
        tech: [
            "Single-strand, multi-strand worms, module m1–10",
            "Wormwheels up to Ø1000 mm, module m1-10",
            <br/>,
            "Single-strand worms, module m3-14",
            "Multi-strand worms, module m3-14",
            "Wormwheels with a separate hub, module m3-14",
            "Wormwheels, module m3-14"
        ],
        bgColor: "#EDEDED",
        iconLocation: [50, 280],
        imgNum: 3,
    }),
    new Product({
        id: 9,
        name: "Blades",
        products: [
            "Wood shredder blades", "Blades for rotary shredders", "Granulator blades"
        ],
        allProducts: [
            "Wood, branch, biomass shredder blades",
            "Blades for rotary trimmer-shredders",
            "Granulator shredder hammers and blades"
        ],
        tech: [
            "Blade heat treatment up to HRC 63",
            "Grinding and honing up to 1000 mm in length",
            "Steel is selected according to technical requirements"
        ],
        bgColor: "#001A3D",
        iconLocation: [50, 264],
        imgNum: 4,
    }),
    new Product({
        id: 10,
        name: "Custom Products",
        products: [],
        allProducts: [
            "Turning (CNC with milling option), CNC slotting, CNC gear milling, coordinate turning, drilling, grinding, laser cutting, bending, welding, heat treatment carbonizing, hardening, surface hardening with high-frequency electric current), and other metalworking.",
            "Defect identification, restoration and repair of axles, gears, gear reducers, moving units of tractors, excavators, forestry machinery, loaders and other machinery."
        ],
        tech: [
            "We carry out production using materials such as carbon steel, alloy steel, low-carbon carbonized steel, stainless steel, non-ferrous metals, plastics, textolite, and other materials."
        ],
        bgColor: "#EC4600",
        iconLocation: [50, 264],
        imgNum: 23,
    }),
]